<template>
  <div class="Map">
    <div class="box">
      <div class="Map_container">
        <div class="Map_content">
          <div class="Tabs">
            <ul>
              <li v-for="(item, index) in Tabs" :key="index" :class="TabsActive == index ? 'TabsActive' : ''" @click="TabsActive = index">
                {{ item }}
              </li>
            </ul>
          </div>
          <div id="Map">
            <div class="China" :class="TabsActive == 0 ? 'isMapShow' : ''">
              <div class="City">
                <ul>
                  <li v-for="(item, index) in City" :key="index" :style="{ top: `${item.pos.top}px`, left: `${item.pos.left}px` }" @click="GetCityInfo(index, item.pos, item.imgSrc)">
                    <div class="City_icon" :class="CityActive == index ? 'City_active' : ''"></div>
                    <p>{{ item.city }}</p>
                  </li>
                </ul>
              </div>
              <div class="City_info" :class="isCityInfo ? 'isCityInfo' : ''" :style="{ top: `${CityInfo.top}px`, left: `${CityInfo.left}px` }">
                <p></p>
                <img :src="CityInfo.imgSrc" />
              </div>
            </div>
            <div class="Word" :class="TabsActive == 1 ? 'isMapShow' : ''">
              <img src="http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/Map_word.png" width="100%" />
            </div>
          </div>
        </div>
        <div class="Map_text">
          <p>西索教育科技集团</p>
          <p>战略布局</p>
          <span></span>
          <span>
            西索教育科技集团的国内总部位于江城武汉，国外分公司位于加拿大和美国，成立于2018年7月，针对留学生研发了“入学－毕业”一站式留学后服务，打造完整的留学产业闭环。作为一家知名跨国企业，战略合作伙伴遍布全球。<br /><br />
            公司目前拥有五大品牌十余种服务产品，已建成全方位的国内外营销体系，在国内外具有极高的美誉度，顺应中国高等教育日渐国际化的大背景，致力于打造国际文化教育资源共享平台和职业规划辅导平台。
          </span>
          <div class="text_city">
            <p>武汉：集团总部</p>
            <img src="http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/Map_text_icon.png" width="24px" />
          </div>
        </div>
      </div>
      <div class="logo">
        <ul>
          <li><img src="http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/Map_logo1.png" width="142px" /></li>
          <li><img src="http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/Map_logo6.png" width="122px" /></li>
          <li><img src="http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/Map_logo3.png" width="140px" /></li>
          <li><img src="http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/Map_logo4.png" width="160px" /></li>
          <li><img src="http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/Map_logo5.png" width="122px" /></li>
          <li><img src="http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/IEEA-logo.png" width="140px" /></li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import City from '../../../plugins/Map_city'
export default {
  data() {
    return {
      TabsActive: 0,
      CityActive: 0,
      Tabs: ['中国地区', '海外地区'],
      City: City,
      isCityInfo: false,
      CityInfo: {
        top: null,
        left: null,
        imgSrc: null
      }
    }
  },
  mounted() {
    this.GetCityInfo(0, City[0].pos, City[0].imgSrc)
  },
  methods: {
    GetCityInfo(index, pos, img) {
      this.CityActive = index
      this.isCityInfo = false
      this.CityInfo = {
        top: pos.top - 22,
        left: pos.left + 26,
        imgSrc: img
      }
      let Dely = setTimeout(() => {
        this.isCityInfo = true
        clearTimeout(Dely)
      }, 100)
    }
  }
}
</script>
<style lang="less" scoped>
.Map {
  width: 100%;
  overflow: hidden;
  min-width: 1440px;
  display: flex;
  justify-content: center;
  margin-top: 70px;
  padding: 0 20px 50px 20px;
  .box {
    width: 1440px;
    flex-shrink: 1;
    overflow: hidden;
  }
  .Map_container {
    width: 100%;
    overflow: hidden;
    max-width: 1440px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .Map_content {
      width: 940px;
      overflow: hidden;
      flex-shrink: 0;
      .Tabs {
        width: 100%;
        overflow: hidden;
        ul {
          display: flex;
          align-items: center;
          li {
            width: 140px;
            overflow: hidden;
            font-size: 20px;
            font-family: 'Bold';
            border-bottom: 2px solid #d1d1d1;
            color: #d1d1d1;
            text-align: center;
            padding: 10px 0;
            cursor: pointer;
            transition: all 0.8s;
            &:last-child {
              margin-left: 2px;
            }
            &.TabsActive {
              color: #2d64fa;
              border-color: #2d64fa;
            }
          }
        }
      }
      #Map {
        width: 846px;
        height: 549px;
        margin-top: 10px;
        position: relative;
        .China {
          width: 715px;
          height: 549px;
          background: url('http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/Map_china.png') center top no-repeat;
          background-size: 100% 100%;
          position: absolute;
          left: 0;
          top: 0;
          opacity: 0;
          transition: opacity 1s;
          z-index: 10;
          .City {
            width: 100%;
            height: 100%;
            overflow: hidden;
            li {
              width: 30px;
              height: 50px;
              overflow: hidden;
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              z-index: 9;
              &:nth-child(8) {
                width: 50px;
                flex-direction: row;
                .City_icon {
                  flex-shrink: 0;
                }
                P {
                  margin-top: 20px;
                }
              }
              .City_icon {
                width: 16px;
                height: 24px;
                overflow: hidden;
                background: url('http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/Map_icon.png') center top no-repeat;
                background-size: 100% 100%;
                &.City_active {
                  background: url('http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/Map_icon_active.png') center top no-repeat !important;
                  background-size: 100% 100% !important;
                }
              }
              p {
                width: 100%;
                font-size: 14px;
                margin-top: 5px;
                line-height: 1;
              }
            }
          }
          .City_info {
            width: 0;
            height: 100px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            z-index: 8;
            p {
              width: 191px;
              height: 3px;
              overflow: hidden;
              background-image: url('http://cso-group.oss-cn-hongkong.aliyuncs.com/CISO/Home/img/Map_line.png');
              background-position: center top;
              background-repeat: no-repeat;
            }
            img {
              width: 0;
              height: 100px;
              position: absolute;
              top: 0;
              left: 201px;
              z-index: 10;
            }
            &.isCityInfo {
              width: 350px;
              transition: width 1s;
              img {
                width: 150px;
                transition: width 0.5s;
                transition-delay: 0.2s;
              }
            }
          }
        }
        .Word {
          width: 100%;
          height: 511px;
          overflow: hidden;
          display: flex;
          align-items: center;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          transition: opacity 1s ease-in-out;
          z-index: 9;
          img {
            width: 846px;
            height: 418px;
          }
        }
        .isMapShow {
          opacity: 1;
        }
      }
    }
    .Map_text {
      flex-grow: 1;
      overflow: hidden;
      color: #111111;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      > p {
        line-height: 1;
      }
      p:nth-child(1) {
        font-size: 30px;
      }
      p:nth-child(2) {
        font-size: 32px;
        margin-top: 17px;
      }
      span:nth-child(3) {
        display: block;
        width: 50px;
        height: 4px;
        background: #e9e9e9;
        margin-top: 17px;
      }
      span:nth-child(4) {
        font-size: 14px;
        margin-top: 40px;
        line-height: 30px;
        width: 475px;
        text-align: justify;
      }
      .text_city {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 87px;
        p {
          font-size: 16px;
          color: #2d64fa;
          margin-right: 10px;
        }
      }
    }
  }
  .logo {
    width: 100%;
    overflow: hidden;
    max-width: 1440px;
    margin-top: 50px;
    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      li {
        width: 280px;
        height: 180px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #d1d1d1;
        &:not(:first-child) {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
